/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './ShowMore.module.css';

const ShowMore = ({ text }) => {
  const [showMore, setShowMore] = useState(false);
  const textLength = text.length;

  return (
    <>
      <span dangerouslySetInnerHTML={{ __html: textLength < 350 || showMore ? text : `${text.substring(0, 350)}` }} />
      {textLength > 350 && !showMore ? (
        <>
          <span className={styles.etc}>...</span>
          <button type="button" className={styles.button} onClick={() => setShowMore(!showMore)}>
            Voir plus
          </button>
        </>
      ) : null}

    </>
  );
};

ShowMore.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ShowMore;
