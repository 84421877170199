import React from 'react';
import {
  BrowserRouter as Router, Routes as Switch, Route,
} from 'react-router-dom';

// Views
import NotFound from './views/NotFound/NotFound';
import EanRedirect from './views/EanRedirect/EanRedirect';
import ProductGrandFrais from './views/ProductGrandFrais/ProductGrandFrais';
import LegalNotice from './views/LegalNotice/LegalNotice';
import PrivacyPolicy from './views/PrivacyPolicy/PrivacyPolicy';
import ProductFresh from './views/ProductFresh/ProductFresh';

const Routes = () => (
  <Router>
    <Switch>
      <Route path="*" element={<NotFound />} />
      <Route exact path="/not-found" element={<NotFound />} />
      <Route exact path="/01/:eanCode" element={<EanRedirect />} />
      <Route exact path="/product/fre/:slug" element={<ProductFresh />} />
      <Route exact path="/product/grf/:slug" element={<ProductGrandFrais />} />
      <Route exact path="/mentions-legales" element={<LegalNotice />} />
      <Route exact path="/politique-de-confidentialite" element={<PrivacyPolicy />} />
    </Switch>
  </Router>
);

export default Routes;
