import api from './_api';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getProductEan = async (code) => {
  await sleep(1000);
  const response = await api.get(`/product/gencode/${code}`);
  return response;
};

const getProductId = async (code) => {
  await sleep(1000);
  const response = await api.get(`/product/${code}`);
  return response;
};

const productService = {
  getProductEan,
  getProductId,
};

export default productService;
