/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import Verre from '../../../assets/img/verre.svg';
import Pain from '../../../assets/img/pain.svg';
import Confiture from '../../../assets/img/confiture.svg';

import styles from './BlockAdvice.module.css';
import cn from '../../../utils/cn';

const PICTOS = {
  boisson: {
    logo: Verre,
  },
  pain: {
    logo: Pain,
  },
  confiture: {
    logo: Confiture,
  },
};

const BlockAdvice = ({
  shop, title, subtitle, text, pictos,
}) => (
  <section className={cn([styles.advice, shop === 'fre' ? styles.themeFresh : styles.themeGrandFrais])}>
    <div className="wrapper">
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
      <ul className={styles.list}>
        {pictos.map((picto) => (
          <li key={picto?.caption} className={styles.listItem}>
            <span className={styles.listPicto}>
              <img
                className={styles.picto}
                src={PICTOS[picto.type].logo}
                alt=""
              />
            </span>
            <span className={styles.listLabel}>{picto.caption}</span>
          </li>
        ))}
      </ul>
    </div>
    <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
  </section>
);

BlockAdvice.propTypes = {
  shop: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string.isRequired,
  pictos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

BlockAdvice.defaultProps = {
  subtitle: null,
};

export default BlockAdvice;
