/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import jsQR from 'jsqr';
import { useNavigate } from 'react-router-dom';

// Services
import productService from '../../../services/productService';

const QRScan = () => {
  const [qr, setQR] = useState(null);
  const canvas = useRef(null);
  const canvasEl = useRef(null);
  const navigate = useNavigate();

  const initVideo = async () => {
    const video = document.createElement('video');
    const tick = () => {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvas.current.drawImage(video, 0, 0, canvasEl.current.width, canvasEl.current.height);
        const imageData = canvas.current.getImageData(0, 0, canvasEl.current.width, canvasEl.current.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert',
        });
        if (code?.data) {
          setQR(code);
          return;
        }
      }
      requestAnimationFrame(tick);
    };
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
      video.srcObject = stream;
      video.setAttribute('playsinline', true);
      video.play();
      requestAnimationFrame(tick);
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    canvasEl.current = document.getElementById('qr-area');
    canvas.current = canvasEl.current.getContext('2d');

    canvasEl.current.width = window.innerWidth;
    canvasEl.current.height = window.innerHeight - 118;
    initVideo();
  }, []);

  const redirectFromQr = async () => {
    const eanCode = [...qr.data.split('/')].pop();

    try {
      const product = await productService.getProductEan(eanCode);

      if (product?.data) {
        navigate(`/01/${eanCode}`);
      }
    } catch (error) {
      if (qr.data.slice(8, 25) === 'parlonsproduit.fr') {
        navigate('/not-found');
      } else {
        window.open(qr.data, '_blank').focus();
      }
    }
  };

  useEffect(() => {
    if (qr) {
      redirectFromQr(qr);
    }
  }, [qr]);

  return (
    <canvas id="qr-area" />
  );
};

export default QRScan;
