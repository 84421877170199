/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import ArrowUpWhite from '../../../assets/img/arrowUp-white.svg';
import ArrowUpBlack from '../../../assets/img/arrowUp-black.svg';

import styles from './Footer.module.css';
import cn from '../../../utils/cn';

const Footer = ({ shop }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className={cn([styles.footer, shop === 'fre' ? styles.themeFresh : styles.themeGrandFrais])}>
      <div className={styles.links}>
        <a href="/mentions-legales" className={cn([styles.link, styles.linkMini])}>Mentions Légales</a>
        <a href="/politique-de-confidentialite" className={cn([styles.link, styles.linkMini])}>Politique de Confidentialité</a>
      </div>
      <button
        type="button"
        id="goToTop"
        className={cn([styles.button, styles.linkRounded])}
        onClick={scrollToTop}
      >
        <img
          className={styles.picto}
          src={shop === 'fre' ? ArrowUpWhite : ArrowUpBlack}
          alt=""
          width="12"
          height="14"
        />
        <span className={styles.label}>haut</span>
      </button>
    </footer>
  );
};

Footer.propTypes = {
  shop: PropTypes.string.isRequired,
};

export default Footer;
