/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './BlockOpinion.module.css';
import cn from '../../../utils/cn';

const BlockOpinion = ({
  shop, title, author, profile, quote,
}) => (
  <section className={cn([styles.opinion, shop === 'fre' ? styles.themeFresh : styles.themeGrandFrais])}>
    <div className={cn(['wrapper', profile ? '' : styles.center])}>
      <h2 className={styles.title}>
        <span className={styles.titleInner} dangerouslySetInnerHTML={{ __html: title }} />
      </h2>
      <div className={styles.author} dangerouslySetInnerHTML={{ __html: author }} />
      {profile ? (
        <div className={styles.profile}>
          <img
            className={styles.profilePic}
            src={`https://${process.env.REACT_APP_DOMAIN}/product-images/${profile}`}
            alt="Profile"
            width="245"
            height="230"
          />
        </div>
      ) : null}
      <p className={styles.quote}>
        &ldquo;
        {' '}
        <span dangerouslySetInnerHTML={{ __html: quote }} />
        {' '}
        &rdquo;
      </p>
    </div>
  </section>
);

BlockOpinion.propTypes = {
  shop: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
};

export default BlockOpinion;
