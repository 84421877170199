import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import ShowMore from '../../atoms/ShowMore/ShowMore';

import PlayWhite from '../../../assets/img/play-white.svg';
import PlayBlack from '../../../assets/img/play-black.svg';
import Cross from '../../../assets/img/cross.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from './BlockGeneric.module.css';
import cn from '../../../utils/cn';

const BlockGeneric = ({
  shop, productName, title, subtitle, text, pictures,
}) => {
  const [modalVideoOpen, setModalVideoOpen] = useState(false);

  const handleSwipe = () => {
    window.dataLayer.push({
      event: 'swipe',
      categorie_page: title,
      produit: productName,
      enseigne: shop === 'fre' ? 'Fresh' : 'Grand Frais',
    });
  };

  return (
    <>
      <section className={cn([styles.generic, shop === 'fre' ? styles.themeFresh : styles.themeGrandFrais])}>
        <div className="wrapper">
          <h2 className={styles.title}>{title}</h2>
          {subtitle ? (
            <h3 className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
          ) : null}
          {text ? (
            <div className={styles.text}>
              <ShowMore text={text} />
            </div>
          ) : null}
        </div>
        {pictures?.length > 0 ? (
          <div className={cn([styles.illustration, pictures[0].caption ? styles.illustrationWithLegend : null])}>
            <Slider
              dots
              arrows={false}
              infinite={false}
              onSwipe={handleSwipe}
            >
              {pictures.map((picture) => (
                <>
                  <div key={picture.id} className={styles.slides}>
                    <img
                      className={styles.slidesImg}
                      src={`https://${process.env.REACT_APP_DOMAIN}/product-images/${picture.id}`}
                      alt=""
                    />
                    {picture.videos.map((video) => (
                      video.videoId ? (
                        <>
                          <button
                            type="button"
                            className={cn([styles.button, styles.buttonVideo])}
                            onClick={() => setModalVideoOpen(true)}
                          >
                            <img
                              src={shop === 'fre' ? PlayWhite : PlayBlack}
                              alt=""
                              width="11"
                              height="17"
                            />
                          </button>
                        </>
                      ) : null
                    ))}
                    {picture.caption ? <div className={styles.legendTitle}>{picture.caption}</div> : null}

                  </div>
                  {picture.textarea ? (
                    <div className={styles.legendText}>{picture.textarea}</div>
                  ) : null }
                </>
              ))}
            </Slider>
          </div>
        ) : null }
      </section>
      {modalVideoOpen && pictures?.map((picture) => (
        picture.videos.map((video) => (
          <div className={styles.modalWrapper}>
            <div className={styles.modalContent}>
              <button
                type="button"
                className={cn([styles.button, styles.modalClose])}
                onClick={() => setModalVideoOpen(false)}
              >
                <img
                  src={Cross}
                  alt=""
                  width="15"
                  height="15"
                />
              </button>
              <div className={styles.videoWrapper}>
                <ReactPlayer
                  className={styles.videoPlayer}
                  url={`https://youtu.be/${video.videoId}`}
                  width="100%"
                  height="100%"
                  controls
                  playing
                  muted
                />
              </div>
            </div>
          </div>
        ))
      ))}
    </>
  );
};

BlockGeneric.propTypes = {
  shop: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  pictures: PropTypes.arrayOf(PropTypes.shape()),
};

BlockGeneric.defaultProps = {
  subtitle: null,
  text: null,
  pictures: null,
};

export default BlockGeneric;
