import React, { useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import LogoAopFR from '../../../assets/img/logo-AOP-fr.svg';
import LogoAopCH from '../../../assets/img/logo-AOP-ch.svg';
import LogoIgp from '../../../assets/img/logo-IGP.png';
import LogoHve from '../../../assets/img/logo-HVE.png';
import LogoLabelRouge from '../../../assets/img/logo-label-rouge.png';
import LogoBio from '../../../assets/img/AB_cert.png';

import LogoAffine from '../../../assets/img/ADF Affinés-100.png';
import LogoCreation from '../../../assets/img/ADF Créations-100.png';

import VacheGreen from '../../../assets/img/vache-green.svg';
import VacheBlack from '../../../assets/img/vache-black.svg';
import BrebisGreen from '../../../assets/img/brebis-green.svg';
import BrebisBlack from '../../../assets/img/brebis-black.svg';
import ChevreGreen from '../../../assets/img/chevre-green.svg';
import ChevreBlack from '../../../assets/img/chevre-black.svg';
import BufflonneGreen from '../../../assets/img/bufflonne-green.svg';
import BufflonneBlack from '../../../assets/img/bufflonne-black.svg';

import Calendar from '../../../assets/img/Calendar.svg';

import Intensite1Green from '../../../assets/img/intensite-1-green.svg';
import Intensite1Black from '../../../assets/img/intensite-1-black.svg';
import Intensite2Green from '../../../assets/img/intensite-2-green.svg';
import Intensite2Black from '../../../assets/img/intensite-2-black.svg';
import Intensite3Green from '../../../assets/img/intensite-3-green.svg';
import Intensite3Black from '../../../assets/img/intensite-3-black.svg';
import Intensite4Green from '../../../assets/img/intensite-4-green.svg';
import Intensite4Black from '../../../assets/img/intensite-4-black.svg';

import PlayWhite from '../../../assets/img/play-white.svg';
import PlayBlack from '../../../assets/img/play-black.svg';
import Cross from '../../../assets/img/cross.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from './BlockProduct.module.css';
import cn from '../../../utils/cn';

const BlockProduct = ({
  shop, pictures, label, video, title, subtitle, natureMilk, milkCaption, tasteIntensity, body, logo, refinement,
}) => {
  const [modalVideoOpen, setModalVideoOpen] = useState(false);
  const [macaron, setMacaron] = useState();
  const [logoImg, setLogoImg] = useState();
  const [animal1, setAnimal1] = useState();
  const [animal2, setAnimal2] = useState();
  const [intensite, setIntensite] = useState();

  const titleList = useMemo(() => {
    const split = title?.split(' ');
    const firstWord = split.shift();
    const others = split?.join(' ');
    return [firstWord, others];
  }, [title]);

  const shouldDisplayPictosTitles = useMemo(() => (
    !!natureMilk
    && !!milkCaption
    && !!tasteIntensity
  ), [
    natureMilk,
    milkCaption,
    tasteIntensity,
  ]);

  useEffect(() => {
    switch (label) {
      case 'dop':
        setMacaron(LogoAopFR);
        break;
      case 'aop':
        setMacaron(LogoAopFR);
        break;
      case 'aop suisse':
        setMacaron(LogoAopCH);
        break;
      case 'igp':
        setMacaron(LogoIgp);
        break;
      case 'lr':
        setMacaron(LogoLabelRouge);
        break;
      case 'hve':
        setMacaron(LogoHve);
        break;
      case 'bio':
        setMacaron(LogoBio);
        break;
      default:
        return '';
    }
    return '';
  }, [label]);

  useEffect(() => {
    switch (logo) {
      case 'adf affinés':
        setLogoImg(LogoAffine);
        break;
      case 'adf créations':
        setLogoImg(LogoCreation);
        break;
      default:
        return '';
    }
    return '';
  }, [logo]);

  useEffect(() => {
    if (shop === 'fre') {
      switch (natureMilk) {
        case 'vache':
          setAnimal1(VacheGreen);
          break;
        case 'brebis':
          setAnimal1(BrebisGreen);
          break;
        case 'chèvre':
          setAnimal1(ChevreGreen);
          break;
        case 'bufflonne':
          setAnimal1(BufflonneGreen);
          break;
        case 'vache et brebis':
          setAnimal1(VacheGreen);
          setAnimal2(BrebisGreen);
          break;
        case 'vache et chèvre':
          setAnimal1(VacheGreen);
          setAnimal2(ChevreGreen);
          break;
        case 'chèvre et brebis':
          setAnimal1(ChevreGreen);
          setAnimal2(BrebisGreen);
          break;
        default:
          return '';
      }
    } else {
      switch (natureMilk) {
        case 'vache':
          setAnimal1(VacheBlack);
          break;
        case 'brebis':
          setAnimal1(BrebisBlack);
          break;
        case 'chèvre':
          setAnimal1(ChevreBlack);
          break;
        case 'bufflonne':
          setAnimal1(BufflonneBlack);
          break;
        case 'vache et brebis':
          setAnimal1(VacheBlack);
          setAnimal2(BrebisBlack);
          break;
        case 'vache et chèvre':
          setAnimal1(VacheBlack);
          setAnimal2(ChevreBlack);
          break;
        case 'chèvre et brebis':
          setAnimal1(ChevreBlack);
          setAnimal2(BrebisBlack);
          break;
        default:
          return '';
      }
    }
    return '';
  }, [shop, natureMilk]);
  useEffect(() => {
    if (shop === 'fre') {
      switch (tasteIntensity) {
        case '1':
          setIntensite(Intensite1Green);
          break;
        case '2':
          setIntensite(Intensite2Green);
          break;
        case '3':
          setIntensite(Intensite3Green);
          break;
        case '4':
          setIntensite(Intensite4Green);
          break;
        default:
          return '';
      }
    } else {
      switch (tasteIntensity) {
        case '1':
          setIntensite(Intensite1Black);
          break;
        case '2':
          setIntensite(Intensite2Black);
          break;
        case '3':
          setIntensite(Intensite3Black);
          break;
        case '4':
          setIntensite(Intensite4Black);
          break;
        default:
          return '';
      }
    }
    return '';
  }, [shop, tasteIntensity]);

  return (
    <>
      <section className={cn([styles.product, shop === 'fre' ? styles.themeFresh : styles.themeGrandFrais])}>
        <div className={styles.illustration}>
          {logoImg ? (
            <div className={styles.logo}>
              <img
                src={logoImg}
                alt={logo}
                width="140"
              />
            </div>
          ) : null}
          {label ? (
            <div className={styles.macaron}>
              <img
                src={macaron}
                alt={label}
                width="80"
                height="80"
              />
            </div>
          ) : null}
          <Slider
            className={styles.slider}
            dots={false}
            arrows={false}
            infinite={false}
          >
            {pictures.map((picture) => (
              <div key={picture?.id} className={styles.slides}>
                <img
                  className={styles.picture}
                  src={`https://${process.env.REACT_APP_DOMAIN}/product-images/${picture.id}`}
                  alt=""
                />
              </div>
            ))}
          </Slider>
        </div>
        { video ? (
          <div className={styles.video}>
            <button
              type="button"
              className={cn([styles.button, styles.buttonVideo])}
              onClick={() => setModalVideoOpen(true)}
            >
              <img
                src={shop === 'fre' ? PlayWhite : PlayBlack}
                alt=""
                width="11"
                height="17"
              />
            </button>
            <div className={styles.videoLabel}>
              <span className={styles.videoLabelBlock}>Regarder&nbsp;</span>
              <span className={styles.videoLabelBlock}>la vidéo</span>
            </div>
          </div>
        ) : null}
        <div className="wrapper">
          <div className={styles.content}>
            <h1 className={styles.title}>
              <span className={styles['title-background']}>{`${titleList?.[0]}`}</span>
              {`\n${titleList?.[1]}`}
            </h1>
            { subtitle ? (
              <span className={styles.banner}>{subtitle}</span>
            ) : null }
            { shouldDisplayPictosTitles ? (
              <div>
                <div className={styles.pictosTitles}>
                  {natureMilk
                    ? (
                      <div className={styles.picto}>
                        <p className={styles.pictoLabel} dangerouslySetInnerHTML={{ __html: milkCaption }} />
                      </div>
                    )
                    : null}
                  {refinement
                    ? (
                      <div className={styles.picto}>
                        <p className={styles.pictoLabel}>affinage</p>
                      </div>
                    )
                    : null}
                  {tasteIntensity
                    ? (
                      <div className={styles.picto}>
                        <p className={styles.pictoLabel}>intensité</p>
                      </div>
                    )
                    : null}
                </div>
                <div className={styles.pictos}>
                  {natureMilk ? (
                    <div className={cn([styles.picto, styles.pictoMilk])}>
                      <div className={cn([styles.pictoContainer, styles.animals])}>
                        <div>
                          {animal1 ? (
                            <img
                              className={styles.pictoImg}
                              src={animal1}
                              alt=""
                              width={animal2 ? '25' : '53'}
                            />
                          ) : null}
                          {animal2 ? (
                            <img
                              className={styles.pictoImg}
                              src={animal2}
                              alt=""
                              width="25"
                            />
                          ) : null}
                        </div>
                        <p className={styles.pictoLabel}>{natureMilk}</p>
                      </div>
                    </div>
                  ) : null}
                  {refinement ? (
                    <div className={cn([styles.picto])}>
                      <div className={cn([styles.pictoContainer, styles.intensity])}>
                        <div className={styles.refinementContent}>
                          <img src={Calendar} alt="" width={20} />
                          <p className={styles.pictoDescription}>{refinement}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {intensite ? (
                    <div className={cn([styles.picto, styles.pictoIntensity])}>
                      <div className={cn([styles.pictoContainer, styles.intensity])}>
                        <img
                          className={cn([styles.pictoImg, styles.pictoImgIntensity])}
                          src={intensite}
                          alt=""
                          width="100"
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null }
          </div>
          <div className={styles.body} dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </section>
      <>
        { modalVideoOpen ? (
          <div className={styles.modalWrapper}>
            <div className={styles.modalContent}>
              <button
                type="button"
                className={cn([styles.button, styles.modalClose])}
                onClick={() => setModalVideoOpen(false)}
              >
                <img
                  src={Cross}
                  alt=""
                  width="15"
                  height="15"
                />
              </button>
              <div className={styles.videoWrapper}>
                <ReactPlayer
                  className={styles.videoPlayer}
                  url={`https://youtu.be/${video}`}
                  width="100%"
                  height="100%"
                  controls
                  playing
                  muted
                />
              </div>
            </div>
          </div>
        ) : null }
      </>
    </>
  );
};

BlockProduct.propTypes = {
  shop: PropTypes.string.isRequired,
  pictures: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  label: PropTypes.string,
  logo: PropTypes.string,
  video: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  natureMilk: PropTypes.string,
  milkCaption: PropTypes.string,
  tasteIntensity: PropTypes.string,
  body: PropTypes.string.isRequired,
  refinement: PropTypes.string,
};

BlockProduct.defaultProps = {
  natureMilk: null,
  milkCaption: null,
  tasteIntensity: null,
  label: null,
  logo: null,
  video: null,
  refinement: null,
};

export default BlockProduct;
