/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './BlockLocation.module.css';
import cn from '../../../utils/cn';

const BlockLocation = ({
  shop, title, subtitle, map, portrait, description,
}) => (
  <section className={cn([styles.location, shop === 'fre' ? styles.themeFresh : styles.themeGrandFrais])}>
    <div className={cn(['wrapper', portrait ? '' : styles.center])}>
      <h2 className={styles.title}>
        <span className={styles.titleInner} dangerouslySetInnerHTML={{ __html: title }} />
      </h2>
      <h3
        className={styles.subtitle}
        dangerouslySetInnerHTML={{ __html: subtitle }}
      />
      {portrait ? (
        <div className={styles.profile}>
          <img
            className={styles.profilePic}
            src={`https://${process.env.REACT_APP_DOMAIN}/product-images/${portrait}`}
            alt="Profile"
            width="245"
            height="230"
          />
        </div>
      ) : null}
      <p className={styles.quote}>
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </p>
      <div className={styles.mapContainer}>
        <img
          className={styles.map}
          src={`https://${process.env.REACT_APP_DOMAIN}/product-images/${map}`}
          alt="Map"
          width="185"
        />
      </div>
    </div>
  </section>
);

BlockLocation.propTypes = {
  shop: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  map: PropTypes.string.isRequired,
  portrait: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default BlockLocation;
