/* eslint-disable max-len */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Page from '../../components/layouts/Page';

import styles from './LegalNotice.module.css';

const LegalNotice = () => {
  const navigate = useNavigate();
  const title = 'Mentions légales';

  return (
    <Page>
      <Helmet>
        <title>
          {title}
          {' '}
          - Parlonsproduits
        </title>
      </Helmet>
      <div className={styles.legals}>
        <button type="button" className={styles.button} onClick={() => navigate(-1)}>&lt; Précédent</button>
        <h1 className={styles.title}>{title}</h1>
        <h2 className={styles.subtitle}>EDITEUR</h2>
        <p className={styles.paragraph}>
          Ce Site «
          {' '}
          <a className={styles.link} href="https://www.parlonsproduit.fr">www.parlonsproduit.fr</a>
          {' '}
          » est édité par la société PROSOL GESTION, Société par Actions Simplifiée au capital de 993 226 Euros. Dont le siège social se situe à CHAPONNAY (69970), 375 Rue Juliette Récamier, immatriculée au RCS de Lyon sous le numéro 378 100 416, et ayant comme numéro de TVA intracommunautaire : FR41 378 100 416.
        </p>
        <p className={styles.paragraph}>Représentée par sa Présidente, la société ZF INVEST.</p>
        <h2 className={styles.subtitle}>HEBERGEUR</h2>
        <p className={styles.paragraph}>
          Le site
          {' '}
          <a className={styles.link} href="https://www.parlonsproduit.fr">www.parlonsproduit.fr</a>
          {' '}
          est hébergé sur les serveurs localisés en Europe d’Amazon Web Services (dénomination Amazon CS Ireland Ltd, Unit 27 – 6400 Cork Airport Business Park – Kinsale Road – Ireland). Amazon CS Ireland Ltd est une société du groupe Amazon Web Services (AWS), dont le siège social se situe à Inc. P.O/ Box 81226 – Seattle, WA 98108-1226, tél : (206) 266-4064 fax: (206) 266-7010.
        </p>
        <h2 className={styles.subtitle}>DIRECTEUR DE LA PUBLICATION</h2>
        <p className={styles.paragraph}>Le Directeur de la publication est Monsieur Hervé VALLAT, représentant légal de ZF Invest, lui-même Président de la société Prosol Gestion.</p>
        <h2 className={styles.subtitle}>PROPRIETE INTELECTUELLE</h2>
        <p className={styles.paragraph}>
          L&apos;ensemble des éléments figurant sur le site
          {' '}
          <a className={styles.link} href="https://www.parlonsproduit.fr">www.parlonsproduit.fr</a>
          {' '}
          (textes, graphismes, photographies, images, plans, noms, logos, marques, créations et œuvres protégeables diverses, bases de données, déposés ou non, etc. …) (ci-après « le Site ») sont la propriété exclusive de Prosol Gestion.
        </p>
        <p className={styles.paragraph}>Il est interdit d&apos;utiliser, reproduire, représenter, modifier, adapter, traduire, copier, distribuer, tout ou partie du site quel qu&apos;en soit le support sans l&apos;autorisation expresse et préalable de Prosol Gestion.</p>
        <p className={styles.paragraph}>Toute reproduction et/ou représentation, totale ou partielle, toute utilisation non expressément autorisée peut engager la responsabilité civile et/ou pénale de son auteur. Prosol Gestionse réserve le droit d&apos;engager des poursuites judiciaires à l&apos;encontre de toute personne qui n&apos;aurait pas respecté cette interdiction.</p>
        <p className={styles.paragraph}>Il en est de même des bases de données figurant, le cas échéant, sur le Site qui sont protégées par les articles du Code de la propriété intellectuelle. Toute extraction ou réutilisation, totale ou partielle desdites bases et de leur contenu est ainsi interdite sans l’autorisation préalable expresse de Prosol Gestion.</p>
        <p className={styles.paragraph}>Les signes distinctifs de Prosol Gestion et de ses partenaires, tels que les noms de domaine, marques, dénominations ainsi que les logos figurant sur le Site sont protégés par le Code de la propriété intellectuelle. Toute reproduction totale ou partielle de ces signes distinctifs effectuée à partir des éléments du Site sans autorisation expresse de Prosol Gestion est donc prohibée, au sens du Code de la propriété intellectuelle.</p>
        <p className={styles.paragraph}>Seule une utilisation conforme à la destination du Site est autorisée.</p>
        <p className={styles.paragraph}>Toute autre utilisation, non expressément autorisée par écrit et au préalable par Prosol Gestion, est prohibée et constitutive de contrefaçon.</p>
        <h2 className={styles.subtitle}>RESPONSABILITE</h2>
        <p className={styles.paragraph}>Prosol Gestion ne saurait être responsable de la qualité du Site, de la perturbation de l’utilisation du Site, de l’impossibilité d’utiliser le Site, des atteintes à la sécurité informatiques ou encore de l’atteinte aux droits des internautes de manière générale.</p>
        <p className={styles.paragraph}>Prosol Gestion ne saurait être tenu responsable des dommages directs ou indirects qui pourraient résulter de l’accès ou de l’utilisation du Site ou des informations y figurant.</p>
        <p className={styles.paragraph}>L’internaute s’engage à utiliser le Site conformément à l’ordre public, aux bonnes mœurs et aux droits des tiers.</p>
      </div>
    </Page>
  );
};

export default LegalNotice;
