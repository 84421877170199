/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet';

import Page from '../../components/layouts/Page';
import Header from '../../components/molecules/Header/Header';
import Footer from '../../components/molecules/Footer/Footer';
import Generic from '../../components/molecules/BlockGeneric/BlockGeneric';
import Product from '../../components/molecules/BlockProduct/BlockProduct';
import Location from '../../components/molecules/BlockLocation/BlockLocation';
import Opinion from '../../components/molecules/BlockOpinion/BlockOpinion';
import DidYouKnow from '../../components/molecules/BlockDidYouKnow/BlockDidYouKnow';
import Advice from '../../components/molecules/BlockAdvice/BlockAdvice';
import Receipts from '../../components/molecules/BlockReceipts/BlockReceipts';
import Regulation from '../../components/molecules/BlockRegulation/BlockRegulation';
import Socials from '../../components/molecules/BlockSocials/BlockSocials';
// import ReadingProgress from '../../components/atoms/ReadingProgress/ReadingProgress';

import productService from '../../services/productService';

import styles from './ProductFresh.module.css';

const ProductFresh = () => {
  const target = React.createRef();
  const shopName = 'fre';
  const params = useParams();
  const navigate = useNavigate();
  const { data: productData, isLoading, isError } = useQuery('get-product', () => productService.getProductId(params.slug.split('-')[0]), {
    retry: false,
  });

  useEffect(() => {
    window.dataLayer.push({
      event: 'pageview',
      pageTitle: 'Page Produit fresh',
    });
  }, []);

  useEffect(() => {
    if (!isLoading && isError) {
      navigate('/not-found');
    }
  }, [isLoading, isError]);

  return (
    !isLoading && productData?.data
      ? (
        <Page>
          <Helmet>
            <title>
              {productData?.data.marketingEntitled}
              {' '}
              - Parlonsproduits
            </title>
            <link rel="icon" href="/favicon-fre.ico" />
            <meta property="og:title" content={productData?.data.marketingEntitled} />
            <meta property="og:description" content={productData?.data.blocks[0].textarea} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content={`https://${process.env.REACT_APP_DOMAIN}/product-images/${productData?.data.blocks[0].pictures[0].id}`} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={productData?.data.marketingEntitled} />
            <meta name="twitter:description" content={productData?.data.blocks[0].textarea} />
            <meta name="twitter:image" content={`https://${process.env.REACT_APP_DOMAIN}/product-images/${productData?.data.blocks[0].pictures[0].id}`} />
          </Helmet>
          <Header
            shop={shopName}
            productName={productData?.data.marketingEntitled}
            productDescription={productData?.data.blocks[0].textarea}
          />
          <main className={styles.main} ref={target}>
            {productData?.data.blocks.map((block) => (
              <React.Fragment key={block.title}>
                { block.type === 'produit' ? (
                  <Product
                    shop={shopName}
                    pictures={block.pictures}
                    label={productData?.data.label}
                    video={block.pictures[0].videos[0].videoId}
                    title={block.title}
                    subtitle={block.subTitle}
                    natureMilk={productData?.data.natureMilk}
                    milkCaption={block.pictos[0].caption}
                    tasteIntensity={block.pictos[1].value}
                    body={block.textarea}
                  />
                ) : null }
                {block.type === 'localisation' ? (
                  <Location
                    shop={shopName}
                    title={block.title}
                    subtitle={block.subTitle}
                    map={block.pictures[0].id}
                  />
                ) : null }
                {block.type === 'avis' ? (
                  <Opinion
                    shop={shopName}
                    title={block.title}
                    author={block.subTitle}
                    profile={block.pictures[0].id}
                    quote={block.textarea}
                  />
                ) : null }
                {block.type === 'generic' ? (
                  <Generic
                    shop={shopName}
                    productName={productData?.data.marketingEntitled}
                    title={block.title}
                    subtitle={block.subTitle}
                    text={block.textarea}
                    pictures={block.pictures}
                  />
                ) : null }
                {block.type === 'insolite' ? (
                  <DidYouKnow
                    shop={shopName}
                    title={block.title}
                    subtitle={block.subTitle}
                    text={block.textarea}
                  />
                ) : null }
                {block.type === 'conseil' ? (
                  <Advice
                    shop={shopName}
                    title={block.title}
                    subtitle={block.subTitle}
                    text={block.textarea}
                    pictos={block.pictos}
                  />
                ) : null }
              </React.Fragment>
            )) }
            <Receipts
              shop={shopName}
              productName={productData?.data.marketingEntitled}
              url="https://www.fresh.fr/recette/"
            />
            {productData?.data.blocks.map((block) => (
              <>
                {block.type === 'reglementaire' ? (
                  <Regulation
                    shop={shopName}
                    pictures={block.pictures}
                  />
                ) : null }
              </>
            )) }
            <Socials
              shop={shopName}
              productName={productData?.data.marketingEntitled}
              facebookUrl="https://www.facebook.com/fresh.lemagasin"
              instagramUrl="https://www.instagram.com/fresh.lemagasin/"
              websiteUrl="https://www.fresh.fr/"
              websiteLabel="www.fresh.fr"
            />
          </main>
          <Footer shop={shopName} />
          {/*
          <ReadingProgress
            shop={shopName}
            target={target}
          />
          */}
        </Page>
      ) : <div className="loader" />
  );
};

export default ProductFresh;
