/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './BlockRegulation.module.css';
import cn from '../../../utils/cn';

const BlockRegulation = ({ shop, pictures }) => (
  <section className={cn([styles.regulation, shop === 'fre' ? styles.themeFresh : styles.themeGrandFrais])}>
    {pictures.map((picture) => (
      <img
        key={picture.id}
        className={styles.picture}
        src={`https://${process.env.REACT_APP_DOMAIN}/product-images/${picture.id}`}
        alt="Recyclage"
        width="320"
      />
    ))}
  </section>
);

BlockRegulation.propTypes = {
  shop: PropTypes.string.isRequired,
  pictures: PropTypes.arrayOf(PropTypes.shape()),
};

BlockRegulation.defaultProps = {
  pictures: null,
};

export default BlockRegulation;
