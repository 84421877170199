/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import EurekaBrown from '../../../assets/img/eureka-brown.svg';
import EurekaYellow from '../../../assets/img/eureka-yellow.svg';

import styles from './BlockDidYouKnow.module.css';
import cn from '../../../utils/cn';

const BlockDidYouKnow = ({
  shop, title, subtitle, text,
}) => (
  <section className={cn([styles.didYouKnow, shop === 'fre' ? styles.themeFresh : styles.themeGrandFrais])}>
    <div className="wrapper">
      <h2 className={styles.title}>{title}</h2>
      <h3 className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
      <img
        className={styles.picto}
        src={shop === 'fresh' ? EurekaBrown : EurekaYellow}
        alt=""
        width="94"
        height="102"
      />
      <p className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  </section>
);

BlockDidYouKnow.propTypes = {
  shop: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default BlockDidYouKnow;
