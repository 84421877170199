/* eslint-disable max-len */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Page from '../../components/layouts/Page';

import styles from './PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const title = 'Politique de confidentialité';

  return (
    <Page>
      <Helmet>
        <title>
          {title}
          {' '}
          - Parlonsproduits
        </title>
      </Helmet>
      <div className={styles.privacy}>
        <button type="button" className={styles.button} onClick={() => navigate(-1)}>&lt; Précédent</button>
        <h1 className={styles.title}>{title}</h1>
        <h2 className={styles.subtitle}>1) Qu’est-ce qu’un cookie ?</h2>
        <p className={styles.paragraph}>Les cookies peuvent être déposés par Prosol Gestion ou par ses partenaires. Dans cette hypothèse, nous vous indiquons l’identité de ces tiers et vous communiquons un lien vers leur politique de confidentialité.</p>
        <p className={styles.paragraph}>Prosol gestion est soucieux de garantir le respect de votre vie privée. C’est pourquoi nous utilisons les cookies dans les limites et conditions prévues par la réglementation et la CNIL.</p>
        <h2 className={styles.subtitle}>2) Quels cookies sont utilisés sur notre site ? Pourquoi ? Pendant combien de temps ?</h2>
        <p className={styles.paragraph}>Les cookies peuvent être déposés par Prosol Gestion ou par ses partenaires. Dans cette hypothèse, nous vous indiquons l’identité de ces tiers et vous communiquons un lien vers leur politique de confidentialité.</p>
        <p className={styles.paragraph}><strong>Les cookies de mesure d’audience</strong></p>
        <p className={styles.paragraph}>Ces cookies recueillent des informations sur la façon dont les visiteurs utilisent le site. Par exemple, le nombre de visites ou les pages les plus visitées, ce qui nous permet d’améliorer les fonctionnalités du site afin de l’adapter à vos attentes.</p>
        <p className={styles.paragraph}>Les cookies de mesure d’audience exemptés de consentement que nous utilisons sont les suivants :</p>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Nom du cookie</th>
                <th>Fournisseur</th>
                <th>Finalité</th>
                <th>Durée de conservation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Google Analytics</td>
                <td>Google</td>
                <td>Comptabiliser les visiteurs</td>
                <td>13 mois maximum</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h2 className={styles.subtitle}>3) Quelle est la durée de conservation d’un cookie ?</h2>
        <p className={styles.paragraph}>Chaque cookie a une durée de vie limitée. A l’issue de cette durée, les cookies seront supprimés de votre navigateur.</p>
        <p className={styles.paragraph}>Dans l’hypothèse où vous retirez votre consentement ou vous opposez aux cookies avant la fin de ce délai, les cookies seront immédiatement supprimés de votre navigateur.</p>
        <h2 className={styles.subtitle}>4) Existe-t-il un lien entre les cookies et les données à caractère personnel ?</h2>
        <p className={styles.paragraph}>Certaines des données qui sont collectées au moyen des cookies sont des données à caractère personnel vous concernant (ex : votre adresse IP, votre identifiant MAC).</p>
        <p className={styles.paragraph}>Les données à caractère personnel collectées au moyen des cookies sont conservées pour une durée maximale de 25 mois à compter de leur collecte.</p>
        <p className={styles.paragraph}>Lorsque les cookies sont fournis par des tiers, l’acceptation de ces cookies peut entraîner un traitement de vos données à caractère personnel effectué par ces tiers. Pour en savoir plus, nous vous invitons à consulter les Politiques de confidentialité de ces tiers.</p>
        <h2 className={styles.subtitle}>5) Comment s’opposer aux cookies ?</h2>
        <p className={styles.paragraph}>Il existe 2 solutions pour vous opposer aux cookies à tout moment.</p>
        <ul className={styles.list}>
          <li className={styles.listItem}>Pour les cookies fondés sur votre consentement : Vous pouvez utiliser notre module de gestion des cookies dont le lien d’accès est situé en bas de chacune des pages de notre site pour vous opposer à tout ou partie de ces cookies [lien].</li>
          <li className={styles.listItem}>Pour tous les cookies : Vous pouvez modifier le paramétrage de votre navigateur internet pour vous opposer à l’ensemble des cookies ou seulement à certains d’entre eux. Les règles de paramétrage sont différentes en fonction du navigateur utilisé.</li>
        </ul>
        <p className={styles.paragraph}><strong>FIREFOX</strong></p>
        <ul className={styles.list}>
          <li className={styles.listItem}>Cliquez sur le bouton « menu » en haut à droite de la fenêtre ouverte symbolisé par trois traits horizontaux</li>
          <li className={styles.listItem}>Sélectionnez le menu « Options »</li>
          <li className={styles.listItem}>Cliquez sur l’onglet « Vie privée et sécurité »</li>
          <li className={styles.listItem}>Dans la rubrique « Vie privée », sélectionnez le mode « personnalisée »</li>
          <li className={styles.listItem}>Cochez la case « cookies » </li>
          <li className={styles.listItem}>Vous pouvez affiner vos préférences en utilisant le menu déroulant qui s’affiche à droite de la case « cookies »</li>
        </ul>
        <p className={styles.paragraph}><strong>INTERNET EXPLORER</strong></p>
        <ul className={styles.list}>
          <li className={styles.listItem}>Accédez au menu « Outils » symbolisé par une roue</li>
          <li className={styles.listItem}>Cliquez sur « Options Internet » </li>
          <li className={styles.listItem}>Cliquez sur l’onglet « Confidentialité »</li>
          <li className={styles.listItem}>Cliquez sur « Avancé »</li>
          <li className={styles.listItem}>Sélectionnez vos préférences </li>
          <li className={styles.listItem}>Sauvegardez vos préférences en cliquant sur « OK »</li>
        </ul>
        <p className={styles.paragraph}><strong>GOOGLE CHROME</strong></p>
        <ul className={styles.list}>
          <li className={styles.listItem}>Cliquez sur l’icône « Menu » symbolisé par trois points</li>
          <li className={styles.listItem}>Cliquez sur l’onglet « Paramètres »</li>
          <li className={styles.listItem}>Dans la rubrique « Confidentialité et sécurité », cliquez sur « Cookies et autres données des sites »</li>
          <li className={styles.listItem}>Sélectionnez vos préférences</li>
        </ul>
        <p className={styles.paragraph}><strong>SAFARI</strong></p>
        <ul className={styles.list}>
          <li className={styles.listItem}>Dans Safari, cliquez sur « Safari », puis « Préférences »</li>
          <li className={styles.listItem}>Cliquez sur l’icône « Confidentialité »</li>
          <li className={styles.listItem}>Vous pouvez « Empêcher le suivi » pour demander aux sites web de ne plus vous suivre et « Bloquer les cookies » pour supprimer les cookies déjà déposés dans votre navigateur</li>
        </ul>
        <h2 className={styles.subtitle}>6) Plus d’informations sur les cookies</h2>
        <p className={styles.paragraph}>
          Nous vous invitons à consulter les rubriques consacrées aux cookies sur site de la CNIL
          {' '}
          <a className={styles.link} href="https://www.cnil.fr/fr/cookies-et-autres-traceurs" target="_blank" rel="noopener noreferrer">
            https://www.cnil.fr/fr/cookies-et-autres-traceurs
          </a>
        </p>
      </div>
    </Page>
  );
};

export default PrivacyPolicy;
