import React from 'react';
import PropTypes from 'prop-types';

import styles from './Page.module.css';

const Page = ({ children }) => (
  <div id={styles.page}>
    {children}
  </div>
);

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Page;
