/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import QRScan from '../../atoms/QRScan/QRScan';

import LogoFresh from '../../../assets/img/logo-fresh.svg';
import LogoParlonsProduit from '../../../assets/img/logo-parlons-produit.png';
import ShareWhite from '../../../assets/img/share-white.svg';
import ShareBlack from '../../../assets/img/share-black.svg';
import ScanGreen from '../../../assets/img/scan-green.svg';
import ScanWhite from '../../../assets/img/scan-white.svg';
import Cross from '../../../assets/img/cross.svg';

import styles from './Header.module.css';
import cn from '../../../utils/cn';

const Header = ({ shop, productName, productDescription }) => {
  const [qrScanOpen, setQrScanOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleShare = () => {
    if (navigator.canShare) {
      navigator.share({
        title: `${shop === 'fre' ? 'Fresh' : 'Grand Frais'} - ${productName}.`,
        text: `J’ai gouté un produit ${shop === 'fre' ? 'Fresh' : 'Grand Frais'} qui pourrait t’intéresser : ${productName}. ${productDescription}`,
        url: window.location.href,
      });
    }
    window.dataLayer.push({
      event: 'partager',
      produit: productName,
      enseigne: shop === 'fre' ? 'Fresh' : 'Grand Frais',
    });
  };

  const handleScan = () => {
    setQrScanOpen(true);
    window.dataLayer.push({
      event: 'scanner autre article',
      enseigne: shop === 'fre' ? 'Fresh' : 'Grand Frais',
    });
  };

  const handleScroll = () => {
    const { body } = document;
    const html = document.documentElement;
    const currentScrollPos = window.pageYOffset;
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    if (currentScrollPos < prevScrollPos || currentScrollPos < 80 || windowHeight + currentScrollPos >= docHeight) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  return (
    <>
      <header className={cn([styles.header, shop === 'fre' ? styles.themeFresh : styles.themeGrandFrais, visible ? styles.visible : ''])}>
        {!qrScanOpen ? (
          <>
            <img
              className={styles.logo}
              src={shop === 'fre' ? LogoFresh : LogoParlonsProduit}
              alt={shop === 'fre' ? 'Fresh' : 'Parlons Produit'}
              width="105"
              height={shop === 'fre' ? 100 : 67}
            />
            <div className={styles.actions}>
              <button type="button" className={cn([styles.share, styles.button])} onClick={handleShare}>
                <img
                  className={styles.sharePicto}
                  src={shop === 'fre' ? ShareWhite : ShareBlack}
                  alt=""
                  width="18"
                  height="12"
                />
                <span className={styles.shareLabel}>Partager</span>
              </button>
              <button type="button" className={styles.scan} onClick={handleScan}>
                <span className={styles.scanLabel}>
                  <span className={shop === 'fre' ? 'text-fresh-300' : ''}>Scannez</span>
                  <br />
                  {' '}
                  un autre
                  {' '}
                  <br />
                  {' '}
                  article
                </span>
                <img
                  className={styles.scanPicto}
                  src={shop === 'fre' ? ScanGreen : ScanWhite}
                  alt=""
                  width="36"
                  height="36"
                />
              </button>
            </div>
          </>
        ) : null }
      </header>
      <>
        { qrScanOpen ? (
          <div className={styles.modalScan}>
            <div className={styles.modalHeader}>
              <img
                className={styles.modalPicto}
                src={ScanWhite}
                alt=""
                width="44"
                height="44"
              />
              <div className={styles.modalInfos}>
                <h2 className={styles.modalTitle}>
                  <span className="text-fresh-100">Scannez</span>
                  {' '}
                  votre article
                </h2>
                <h3 className={styles.modalSubtitle}>Les QR code de nos produits se trouvent au verso des packagings</h3>
              </div>
              <button type="button" className={styles.modalClose} onClick={() => setQrScanOpen(false)}>
                <img
                  src={Cross}
                  alt=""
                  width="15"
                  height="15"
                />
              </button>
            </div>
            <div className={styles.modalBody}>
              <QRScan />
            </div>
          </div>
        ) : null }
      </>
    </>
  );
};

Header.propTypes = {
  shop: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  productDescription: PropTypes.string.isRequired,
};

export default Header;
