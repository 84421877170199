import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Page from '../../components/layouts/Page';

import LogoParlonsProduit from '../../assets/img/logo-parlons-produit.png';

import styles from './NotFound.module.css';
import cn from '../../utils/cn';

const NotFound = () => (
  <Page>
    <Helmet>
      <title>Page non trouvée - Parlonsproduits</title>
    </Helmet>
    <div className={styles.notFound}>
      <header className={styles.header}>
        <h1 className={styles.title}>Les informations de votre produit seront bientôt disponibles</h1>
      </header>
      <main className={styles.main}>
        <img
          className={styles.logo}
          src={LogoParlonsProduit}
          alt="Parlons Produit"
          width="250"
          height="158"
        />
      </main>
      <footer className={styles.footer}>
        <Link
          to="/mentions-legales"
          className={cn([styles.link, styles.linkMini])}
        >
          Mentions Légales
        </Link>
        <Link
          to="/politique-de-confidentialite"
          className={cn([styles.link, styles.linkMini])}
        >
          Politique De Confidentialité
        </Link>
      </footer>
    </div>
  </Page>
);

export default NotFound;
