/* eslint-disable no-underscore-dangle */
import * as axios from 'axios';

const Axios = axios.create({
  baseURL: `https://${process.env.REACT_APP_API}`,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});

export default Axios;
