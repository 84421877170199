/* eslint-disable max-len */
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import productService from '../../services/productService';

const EanRedirect = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {
    data: productData, isLoading, isError, isSuccess,
  } = useQuery('get-product', () => productService.getProductEan(params.eanCode), {
    retry: false,
  });

  useEffect(() => {
    if (!isError && !isLoading) {
      navigate(`/product/grf/${productData.data?.code}-${productData.data?.marketingEntitled.replace(/\W+/g, '-')}`, { state: { product: productData.data, eanCode: params.eanCode } });
    }
    if (isError && !isLoading) {
      navigate('/not-found');
    }
  }, [productData, isLoading, isError, isSuccess]);

  return null;
};

export default EanRedirect;
