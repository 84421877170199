import { QueryClient, QueryClientProvider } from 'react-query';
import TagManager from 'react-gtm-module';
import Routes from './Routes';

const queryClient = new QueryClient();

TagManager.initialize({
  gtmId: 'GTM-NCHJ9M2',
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Routes />
  </QueryClientProvider>
);

export default App;
