/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import FacebookGreen from '../../../assets/img/facebook-green.svg';
import FacebookBlack from '../../../assets/img/facebook-black.svg';
import InstagramGreen from '../../../assets/img/instagram-green.svg';
import InstagramBlack from '../../../assets/img/instagram-black.svg';

import styles from './BlockSocials.module.css';
import cn from '../../../utils/cn';

const BlockSocials = ({
  shop, productName, facebookUrl, instagramUrl, websiteUrl, websiteLabel,
}) => {
  const handleSocial = (socialName) => {
    window.dataLayer.push({
      event: 'suivez-nous',
      social_media: socialName,
      produit: productName,
      enseigne: shop === 'fre' ? 'Fresh' : 'Grand Frais',
    });
  };

  return (
    <section className={cn([styles.socials, shop === 'fre' ? styles.themeFresh : styles.themeGrandFrais])}>
      <h2 className={styles.title}>Suivez-nous !</h2>
      <div className={styles.links}>
        <a
          className={cn([styles.link, styles.linkRounded])}
          href={facebookUrl}
          rel="noopener noreferrer nofollow"
          target="_blank"
          onClick={() => handleSocial('Facebook')}
        >
          <img
            className={styles.picto}
            src={shop === 'fre' ? FacebookGreen : FacebookBlack}
            alt="Facebook"
            width="12"
            height="23"
          />
        </a>
        <a
          className={cn([styles.link, styles.linkRounded])}
          href={instagramUrl}
          rel="noopener noreferrer nofollow"
          target="_blank"
          onClick={() => handleSocial('Instagram')}
        >
          <img
            className={styles.picto}
            src={shop === 'fre' ? InstagramGreen : InstagramBlack}
            alt="Instagram"
            width="23"
            height="23"
          />
        </a>
        <a
          className={styles.link}
          href={websiteUrl}
          rel="noopener noreferrer nofollow"
          target="_blank"
          onClick={() => handleSocial('Website')}
        >
          {websiteLabel}
        </a>
      </div>
    </section>
  );
};

BlockSocials.propTypes = {
  shop: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  facebookUrl: PropTypes.string.isRequired,
  instagramUrl: PropTypes.string.isRequired,
  websiteUrl: PropTypes.string.isRequired,
  websiteLabel: PropTypes.string.isRequired,
};

export default BlockSocials;
