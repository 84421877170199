/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './BlockReceipts.module.css';
import cn from '../../../utils/cn';

const BlockReceipts = ({ shop, productName, url }) => (
  <section className={cn([styles.receipts, shop === 'fre' ? styles.themeFresh : styles.themeGrandFrais])}>
    <a className={styles.link} href={url} rel="noopener noreferrer nofollow" target="_blank" onClick={() => window.dataLayer.push({ event: 'recettes', produit: productName, enseigne: shop === 'fre' ? 'Fresh' : 'Grand Frais' })}>Voir toutes nos recettes</a>
  </section>
);

BlockReceipts.propTypes = {
  shop: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default BlockReceipts;
